import { StyleSheet } from "react-native";
import { View } from "../atoms/styled";
import clsx from "clsx";
import { ViewColors, bgBorderClasses, hoverClasses } from "../utils/colors";

export type AnimatedBackgroundViewProps = {
  color: ViewColors;
  border?: boolean | ViewColors;
  textClassname?: string;
  dark?: boolean;
};

export default function AnimatedBackgroundView({
  color = "white",
  className,
  style,
  border,
  ...rest
}: React.ComponentPropsWithoutRef<typeof View> & AnimatedBackgroundViewProps) {
  return (
    <View
      style={StyleSheet.flatten([style])}
      className={clsx(
        "transition-colors",
        {
          "border-2": border,
        },
        bgBorderClasses[color],
        hoverClasses[color],
        className,
      )}
      {...rest}
    />
  );
}
